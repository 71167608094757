import { PoolConfig, QuoteToken, PoolCategory } from './types'

const MASH = 'MASH';

const pools: PoolConfig[] = [
  {
    sousId: 8,
    tokenName: 'Mash-SlimeV2 LP',
    stakingTokenName: QuoteToken[MASH],
    stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
    contractAddress: {
      97: '0xab76B5DA1af1C8091B8B8C87533627BB94D2FE36',
      56: '0xab76B5DA1af1C8091B8B8C87533627BB94D2FE36',
	  943: '0x1969607168ffdfdfa95b09588b65d3765f26ec3e',
	  369: '0x4C8827D6b204814a19C51Cefd4CabA1aE5FB67DA',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://app.slime.finance/',
    harvest: true,
    isFinished: false,
    tokenPerBlock: '0.000856329483621793',
    sortOrder: 1,
    tokenDecimals: 18,
    startBlock: 7323438,
    endBlock: 7525038,
    withBurnFee:true,
    isLPStake:false,
    isLPReward:true,
    burnFee:0,
    slimeRounding:5,
    image: 'mash-slime'
  },
 // {
 //   sousId: 2,
 //   tokenName: 'MASH-BUSD LP',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0x00ea75D83B3Cb954447BADc9F807631416933C48',
 //     56: '0x00ea75D83B3Cb954447BADc9F807631416933C48',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://marshmallowdefi.com/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.002777777777777777',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7386238,
 //   endBlock: 7587838,
 //   withBurnFee: true,
 //   isLPStake:false,
 //   isLPReward:true,
 //   burnFee: 3,
 //   slimeRounding: 5,
 //   image: 'mash-busd'
 // },
 // {
 //   sousId: 1,
 //   tokenName: 'MASH-BNB LP',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0xB949337009c97298c06Acbb585058887d10Ba3f9',
 //     56: '0xB949337009c97298c06Acbb585058887d10Ba3f9',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://marshmallowdefi.com/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.000124007936507936',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7386238,
 //   endBlock: 7587838,
 //   withBurnFee: true,
 //   isLPStake:false,
 //   isLPReward:true,
 //   burnFee: 3,
 //   slimeRounding: 5,
 //   image: 'mash-bnb'
 // },
 // {
 //   sousId: 3,
 //   tokenName: 'MASH-PLUM LP V2',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0x99eC02568f4090A066c027d3172d317db0DD90a4',
 //     56: '0x99eC02568f4090A066c027d3172d317db0DD90a4',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://plumcake.finance/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.002901785714285714',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7488488,
 //   endBlock: 7690088,
 //   withBurnFee: true,
 //   isLPStake:false,
 //   isLPReward:true,
 //   burnFee: 3,
 //   slimeRounding: 5,
 //   image: 'mash-plum'
 // },
 // {
 //   sousId: 4,
 //   tokenName: 'MASH-TAKO APE LP',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0x56d93Aefaf766b8DA4a57b9805057BD2822fC73a',
 //     56: '0x56d93Aefaf766b8DA4a57b9805057BD2822fC73a',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://apeswap.finance/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.008933531746031746',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7516658,
 //   endBlock: 7718258,
 //   withBurnFee: true,
 //   isLPStake:false,
 //   isLPReward:true,
 //   isApe: true,
 //   burnFee: 3,
 //   slimeRounding: 5,
 //   image: 'mash-tako'
 // },
 // {
 //   sousId: 9,
 //   tokenName: 'Mash-SlimeV2 LP',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0x99B75635683E874d8ea3e2696a25e25AeDFDC454',
 //     56: '0x99B75635683E874d8ea3e2696a25e25AeDFDC454',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://app.slime.finance/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.002251984126984126',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7544928,
 //   endBlock: 7746528,
 //   withBurnFee: true,
 //   isLPStake: false,
 //   isLPReward: true,
 //   burnFee: 0,
 //   slimeRounding: 5,
 //   image: 'mash-slime'
 // },
 // {
 //   sousId: 10,
 //   tokenName: 'BTCB',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0x08fa76bAA1D731F54248d45Ab19F9E8642f49b2D',
 //     56: '0x08fa76bAA1D731F54248d45Ab19F9E8642f49b2D',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://marshmallowdefi.com/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.000000258928571428',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7605838,
 //   endBlock: 7807438,
 //   withBurnFee: true,
 //   isLPStake: false,
 //   isLPReward: false,
 //   burnFee: 3,
 //   slimeRounding: 5,
 //   image: 'mash-btcb'
 // },
 // {
 //   sousId: 11,
 //   tokenName: 'CAKE',
 //   stakingTokenName: QuoteToken[MASH],
 //   stakingTokenAddress: '0x787732f27d18495494cea3792ed7946bbcff8db2',
 //   contractAddress: {
 //     97: '0xdeB31d3cb07f9F25D162B4cF4dBC79BC2983d39a',
 //     56: '0xdeB31d3cb07f9F25D162B4cF4dBC79BC2983d39a',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://pancakeswap.finance/',
 //   harvest: true,
 //   isFinished: false,
 //   tokenPerBlock: '0.000570436507936507',
 //   sortOrder: 1,
 //   tokenDecimals: 18,
 //   startBlock: 7605838,
 //   endBlock: 7807438,
 //   withBurnFee: true,
 //   isLPStake: false,
 //   isLPReward: false,
 //   burnFee: 3,
 //   slimeRounding: 5,
 //   image: 'mash-wcake'
 // },
 // {
 //   sousId: 1,
 //   tokenName: 'TWT',
 //   stakingTokenName: QuoteToken.SYRUP,
 //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
 //   contractAddress: {
 //     97: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
 //     56: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
 //   },
 //   poolCategory: PoolCategory.CORE,
 //   projectLink: 'https://trustwallet.com/',
 //   harvest: true,
 //   tokenPerBlock: '20',
 //   sortOrder: 999,
 //   isFinished: true,
 //   tokenDecimals: 18,
 // },
]

export default pools
