export default {
  cake: {
    56: '',
    97: '',
	943: '',
	369: '0xF84b84dAAce6Ac00DbBAed26CA32Ff3570Aaf66C', // this is OG address.. to help with places that use cake
  },
  pussy: {
	56: '',
    97: '',
	943: '',
	369: '0x733099917CbA30614e4b2ef1de8A551645665143',  
  },
  xcat: {
	56: '',
    97: '',
	943: '0x0F4E9e9cec0d6F76602057f35626Af8A0d8B5c47',  
  },
  og: {
	56: '',
    97: '',
	943: '',
	369: '0xF84b84dAAce6Ac00DbBAed26CA32Ff3570Aaf66C',
  },
  wpls: {
	56: '',
    97: '',
	943: '0x70499adEBB11Efd915E3b69E700c331778628707',
	369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
  },
  dai: {
	56: '',
    97: '',
	943: '0x826e4e896CC2f5B371Cd7Bb0bd929DB3e3DB67c0',
	369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
  },
  hex: {
	56: '',
    97: '',
	943: '',
	369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
  },
  masterChef: {
    56: '',
    97: '',
	943: '0x615d357e67879B1CbA4Bf3277EF2dBc55a8E8e62',
	369: '0x0690CAB182DF8b1f7C07780fcE1C992AEa1d9115',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
	943: '',
  },
  lottery: {
    56: '',
    97: '',
	943: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
	943: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
	943: '0xA70aB3380FE08e3Ac8F303EcC21BAe8f83b487cF',
	369: '0xdc6ed7C2EE5ab7fd303334B34F7524Cbb463DE57',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
	943: '',
  },
  wcake: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '',
	943: '',
  },
  btcb: {
    56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    97: '',
	943: '',
  },
  vault: {
    56: '0xd456be0ff7007b3d8ad656136487a23e771f5762',
    97: '',
	943: '',
  },
  plum: {
    56: '0xdE8eD8c9480EA12D050182AA99630B235dE30f83',
    97: '',
	943: '',
  },
  slime: {
    56: '0x23b06097F8FE2DD9D3dF094D3ee8319Daa8756c1',
    97: '',
	943: '',
  },
  tako: {
    56: '0x2F3391AeBE27393aBa0a790aa5E1577fEA0361c2',
    97: '',
	943: '',
  }
}
