import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchFarms = async () => {
  const data = await Promise.all(
    // farmsConfig.filter(farmConfig => farmConfig.isVisible !== false).map(async (farmConfig) => {
	farmsConfig.map(async (farmConfig) => {
      const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals
      ] = await multicall(erc20, calls)

      let tokenAmount;
      let lpTotalInQuoteToken;
      let tokenPriceVsQuote;
      if(farmConfig.isTokenOnly){
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals));
        if(farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD){
          tokenPriceVsQuote = new BigNumber(1);
        }else{
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(10).pow(quoteTokenDecimals));
		  tokenPriceVsQuote = tokenPriceVsQuote.div(new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)));
        }
        
		lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
		
		// console.log("QuoteTokenSymbol:", farmConfig.quoteTokenSymbol.toString());
		// console.log("tokenPriceVsQuote:", tokenPriceVsQuote.toString());
		// console.log("tokenAmount:", tokenAmount.toString());
		// console.log("quoteTokenBlanceLP:", quoteTokenBlanceLP.toString());
		// console.log("quoteTokenDecimals:", quoteTokenDecimals.toString());
		// console.log("tokenBalanceLP:", tokenBalanceLP.toString());
		// console.log("tokenDecimals:", tokenDecimals.toString());
		
      }else{
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if(tokenAmount.comparedTo(0) > 0){
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
        }else{
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
        }
      }

	  let info;
	  let totalAllocPoint;
	  let toniPerBlock;

	  if (farmConfig.isVisible === false) {
		info = {};  // Empty object
		totalAllocPoint = '0';  // Static value
		toniPerBlock = '0';  // Static value
	} else {
      [info, totalAllocPoint, toniPerBlock] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          // params: [farmConfig.isVisible ? farmConfig.pid : 0],
		  params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          name: 'toniPerBlock',
        },
      ])
	}

	  let allocPoint;
	  let poolWeight;

// If info or totalAllocPoint doesn't exist or are not as expected, default to zero
if (info && info.allocPoint && info.allocPoint._hex) {
  allocPoint = new BigNumber(info.allocPoint._hex);
} else {
  allocPoint = new BigNumber(0);
}


if (totalAllocPoint) {
  poolWeight = allocPoint.div(new BigNumber(totalAllocPoint));
} else {
  poolWeight = new BigNumber(0);
}

      // const allocPoint = new BigNumber(info.allocPoint._hex)
    //  const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info && info.depositFeeBP ? info.depositFeeBP : 0,
        toniPerBlock: new BigNumber(toniPerBlock).toNumber(),
      }
    }),
  )
  return data
}

export default fetchFarms
