import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   items: [
	 //  {
  //       label: 'OG / PLS Liquidity Pool',
  //       href: 'https://',
  //     },
	 //  {
  //       label: 'PUSSY / PLS Liquidity Pool',
  //       href: 'https://',
  //     },
  //   ],
  // },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Trade PUSSY / PLS',
        href: 'https://swap.9mm.pro/swap?outputCurrency=0x733099917CbA30614e4b2ef1de8A551645665143',
		// target: '_blank'
      },
      {
        label: 'Trade OG / PLS',
        href: 'https://swap.9mm.pro/swap?outputCurrency=0xF84b84dAAce6Ac00DbBAed26CA32Ff3570Aaf66C',
		// target: '_blank'
      },
    ],
  },
  // {
  //   label: 'SafeFarms',
  //   icon: 'FarmIcon',
  //   href: 'http://safefarms.marshmallowdefi.com/info',
  // },
  // {
  //   label: 'Launch Pools',
  //   icon: 'PoolIcon',
  //   href: '/launch',
  // },
  // {
  //   label: 'Audit',
  //   icon: 'AuditIcon',
  //   href: 'https://marshmallowdefi.com/Audit.pdf',
  // },


  // {
  //   label: 'Audit',
  //   icon: 'AuditIcon',
  //   items: [
  //     {
  //       label: 'Techrate',
  //       href: '/Audit.pdf',
  //     },
  //     {
  //       label: 'CERTIK',
  //       href: 'https://certik.org/projects/marshmallow',
  //     },
  //   ],
  // },
  // 
  // {
  //   label: 'Partnership',
  //   icon: 'AuditIcon',
  //   href: 'https://forms.gle/UdsH5aEU3JxroM5G9',
  // },
  {
    label: 'Token Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'OG / WPLS',
        href: 'https://dexscreener.com/pulsechain/0x4c8827d6b204814a19c51cefd4caba1ae5fb67da',
      },
      {
        label: 'PUSSY / WPLS',
        href: 'https://dexscreener.com/pulsechain/0x269E4923C060EEB14E744ad379821dcfBc541d6a',
      },
    ],
  },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: "Github",
  //       href: "https://github.com/MarshmallowSwap",
  //     },
  //     {
  //       label: "Docs",
  //       href: "https://marshmallowswap.gitbook.io/marshmallowswap/",
  //     },
  //     {
  //       label: "Blog",
  //       href: "https://medium.com/@marshmallowdefi",
  //     },
  //     {
  //       label: "Telegram",
  //       href: "https://t.me/MarshmallowDeFi"
  //     },
  //   ],
  // },
]

export default config
